
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Toast } from "vant";
import { loginCommon } from "@/utils/index";
import { getDistributionChannelData } from "@/utils/weChatLogin";
import qs from "qs";

@Component({})
export default class WechatOauthMiddlePage extends Mixins(Mixin) {
  mounted(): void {
    let code = "";
    let query = location.search
      ? qs.parse(location.search.replace("?", ""))
      : null;
    if (query) {
      code = query.code || "";
    }

    console.log("");
    console.log("WechatOauthMiddlePage >>> query", query);

    let distributionChannelData = getDistributionChannelData(query.state);

    console.log(
      "WechatOauthMiddlePage >>> distributionChannelData",
      distributionChannelData
    );

    if (code) {
      Toast.loading({
        duration: 0,
        forbidClick: true,
      });
      this.$api.oauth2Api.socialLogin.oauth("MP", { code }, ({ data }) => {
        Toast.clear();
        let oauth2AccessToken = data.oauth2AccessToken;
        if (oauth2AccessToken) {
          loginCommon(oauth2AccessToken, this.$api);
          let beforeLoginPage = this.RouteRecordModule.beforeLoginPage;
          if (beforeLoginPage && beforeLoginPage.path != "/login") {
            this.$router.replace({
              path: beforeLoginPage.path,
              query: Object.assign(
                distributionChannelData || {},
                beforeLoginPage.query
              ),
            });
          } else {
            /**
             * 如果登录前没有路由记录，那么说明登录前不在当前单页应用中
             * 此时倒退就会来到应用外的页面，因此这里重定向到首页
             */
            if (distributionChannelData) {
              // 渠道分销
              this.$router.replace({
                path:
                  "/channeldistribution/" +
                  (distributionChannelData.templateType == "custom"
                    ? "custom"
                    : "common"),
                query: distributionChannelData,
              });
            } else {
              this.$router.replace("/home");
            }
          }
        } else {
          // 未绑定，进入绑定流程
          this.$router.replace({
            path: "/wechatbind",
            query: Object.assign(distributionChannelData || {}, {
              openId: data.openId || "",
              unionId: data.unionId || "",
            }),
          });
        }
      });
    } else {
      this.RouteRecordModule.GO_TO_LOGIN_PAGE_PERSIST({ router: this.$router });
    }
  }
}
