import { httpNoTokenVerify } from "@/plugins/axios";
import { ChannelModule } from "@/store/modules/channel";
import { isWechat } from "@/utils/index";
import { RouteRecordModule } from "@/store/modules/routeRecord";
import router from "@/router";
import { monitorEvent } from "@/utils/youMengMonitor";

const STATE_SEPARATOR = "@state@";

export function getDistributionChannelData(state: string): {
  distributionChannelId: string;
  distributionSeriesId: string;
  templateType: string;
} | null {
  if (!state) {
    return null;
  }
  const stateDatas = state.split(STATE_SEPARATOR);
  const length = stateDatas.length;
  if (length < 3) {
    return null;
  }
  return {
    distributionChannelId: stateDatas[length - 3],
    distributionSeriesId: stateDatas[length - 2],
    templateType: stateDatas[length - 1],
  };
}

export function wechatLogin(
  state: string,
  // 是否缓存登录前的页面，供登录后跳转（登录页不缓存，进入登录页前已经缓存了）
  isSetBeforeLoginPage?: boolean,
  failCallback?: () => void,
  successCallback?: () => void
): void {
  const query = router.currentRoute.query;

  // 渠道分销传参
  const distributionChannelId = String(query.distributionChannelId || "");
  const distributionSeriesId = String(query.distributionSeriesId || "");
  if (distributionChannelId && distributionSeriesId) {
    state +=
      STATE_SEPARATOR +
      distributionChannelId +
      STATE_SEPARATOR +
      distributionSeriesId;
    const templateType = String(query.templateType || "");
    state += STATE_SEPARATOR + (templateType || "common");
  }

  if (ChannelModule.channel == "plat_h5" && isWechat()) {
    // 从后台获取微信网页授权服务的 URL
    httpNoTokenVerify({
      method: "get",
      url: "/oauth2/auth-url",
      params: { state, scope: "snsapi_userinfo" },
    })
      .then(({ data }) => {
        if (isSetBeforeLoginPage) {
          /**
           * 在跳到微信授权页面前，在 localStorage 中存一下当前路由信息
           * 以便在授权成功或绑定成功后跳回来
           * 注意：登录页不缓存，进入登录页前已经缓存了
           */
          RouteRecordModule.SET_beforeLoginPage_PERSIST(router);
        }
        monitorEvent("AccountLogin_ClickWeChat", "点击微信登录"); // 埋点：注册登录，点击微信登录
        window.location.href = String(data);
        if (successCallback) {
          successCallback();
        }
      })
      .catch((error: string) => {
        console.error("error", error);
        if (failCallback) {
          failCallback();
        }
      });
  } else {
    if (failCallback) {
      failCallback();
    }
  }
}
